export default class ValidationErrorDto {
  constructor(
    public readonly message: string,
    public readonly propertyPath: string
  ) {}

  public get fullMessage(): string {
    return this.propertyPath.length > 0 ? `${this.message} (${this.propertyPath})` : this.message;
  }

  public static createFromObject(data: any) {
    return new ValidationErrorDto(data.message ?? '', data.propertyPath ?? '');
  }
}
