import Command from '@app/module/core/application/command-bus/command';
import CreateRateDto from '@app/module/rate/domain/dto/create-rate-dto';

export default class CreateRateCommand extends Command {
  constructor(
    public readonly dto: CreateRateDto,
  ) {
    super();
  }
}
