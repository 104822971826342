import CreateRateAuthorDto from '@app/module/rate/domain/dto/create-rate-author-dto';

export default class CreateRateDto {
  constructor(
    public readonly externalId: string,
    public readonly externalType: string,
    public readonly externalResource: string,
    public readonly rate: number,
    public readonly title: string,
    public readonly opinion: string,
    public readonly rateAuthor: CreateRateAuthorDto,
    public csrfToken?: string
  ) {}

  public static createFromObject(data: any) {
    return new CreateRateDto(
      data.externalId,
      data.externalType,
      data.externalResource,
      data.rate,
      data.title,
      data.opinion,
      CreateRateAuthorDto.createFromObject(data.rateAuthor || {}),
      data.csrfToken,
    );
  }
}
