import AbstractPaginatedQuery from '@app/module/api/application/query/abstract-paginated-query';
import PaginationDto from '@app/module/api/domain/dto/pagination-dto';
import { Injectable } from '@angular/core';
import RateListItemDto from '@app/module/rate/domain/dto/rate-list-item-dto';

@Injectable({
  providedIn: 'root',
})
export default class GetRateListQuery extends AbstractPaginatedQuery<RateListItemDto> {
  public async getList(
    type: string,
    id: string,
    page: number = AbstractPaginatedQuery.defaultPage,
    limit: number = AbstractPaginatedQuery.defaultLimit
  ): Promise<PaginationDto<RateListItemDto>> {
    return this.paginate(`rate/list/${type}/${id}`, page, limit);
  }

  protected mapItems(items: any[]): RateListItemDto[] {
    return items.map((item: any) => RateListItemDto.createFromObject(item));
  }
}
