import Command from '@app/module/core/application/command-bus/command';

export default class LoadCurrentRateListCommand extends Command {
  constructor(
    public readonly externalType: string,
    public readonly externalId: string,
    public readonly page: number,
    public readonly limit: number,
  ) {
    super();
  }
}
