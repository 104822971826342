import { Injectable } from '@angular/core';
import { observable } from 'mobx-angular';
import { makeObservable } from 'mobx';
import PaginationDto from '@app/module/api/domain/dto/pagination-dto';
import RateListItemDto from '@app/module/rate/domain/dto/rate-list-item-dto';

@Injectable({
  providedIn: 'root',
})
export default class CurrentRateListStore {
  @observable public pagination?: PaginationDto<RateListItemDto>;

  constructor() {
    makeObservable(this);
  }
}
