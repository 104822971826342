import { Injectable } from '@angular/core';
import { observable } from 'mobx-angular';
import { makeObservable } from 'mobx';

@Injectable({
  providedIn: 'root',
})
export default class RateModalStore {
  @observable public isOpen: boolean = false;

  constructor() {
    makeObservable(this);
  }
}
