<div class="app-rate-create-form">
  <mat-tab-group animationDuration="0ms" [selectedIndex]="currentStep">
    <mat-tab>
      <form [formGroup]="formStep1" class="form">
        <div class="form-group">
          <div class="app-rate-create-form__rate-input">
            <ion-label>Ocena: </ion-label>
            <ng-app-rate [size]="36" [disableAfterRate]="false" (changeEvent)="onRateChange($event)"></ng-app-rate>
            <input type="hidden" required formControlName="rate" />
          </div>
        </div>
        <div class="form-group">
          <ion-input
            fill="outline"
            label-placement="floating"
            mode="md"
            label="Tytuł"
            type="text"
            required
            minlength="7"
            maxlength="255"
            errorText="Musi składać się z co najmniej 7 znaków"
            formControlName="title">
          </ion-input>
        </div>
        <div class="form-group">
          <ion-textarea
            fill="outline"
            label-placement="floating"
            mode="md"
            label="Opinia"
            rows="8"
            required
            minlength="20"
            auto-grow
            errorText="Musi składać się z co najmniej 20 znaków"
            formControlName="opinion">
          </ion-textarea>
        </div>
      </form>
      <ion-segment class="app-rate-create-form__buttons">
        <ion-segment-button [disabled]="true">
          <ion-button fill="clear" class="app-rate-create-form__btn-back">
            <span>Cofnij</span>
          </ion-button>
        </ion-segment-button>
        <ion-segment-button [disabled]="!formStep1.valid" (click)="setCurrentStep(1)">
          <ion-button fill="outline">Dalej</ion-button>
        </ion-segment-button>
      </ion-segment>
    </mat-tab>
    <mat-tab>
      <form [formGroup]="formStep2" class="form">
        <div class="form-group">
          <ion-input
            fill="outline"
            label-placement="floating"
            mode="md"
            label="E-mail"
            type="email"
            required
            formControlName="email"
            errorText="Nieprawidłowy e-mail" email>
          </ion-input>
        </div>
        <div class="form-group">
          <ion-input
            fill="outline"
            label-placement="floating"
            mode="md"
            label="Imię"
            type="text"
            required
            formControlName="firstName">
          </ion-input>
        </div>
        <div class="form-group">
          <div class="app-rate-create-form__checkbox" (click)="onConsentContainerClick($event)">
            <ion-checkbox
              labelPlacement="end"
              mode="md"
              required
              formControlName="consent"
              (ionChange)="onConsentChange($event)">
            </ion-checkbox>
            <span [innerHTML]="consentText" class="app-rate-create-form__checkbox-label"></span>
          </div>
        </div>
      </form>
      <ion-segment class="app-rate-create-form__buttons">
        <ion-segment-button (click)="setCurrentStep(0)">
          <ion-button fill="clear" class="app-rate-create-form__btn-back">
            <span>Cofnij</span>
          </ion-button>
        </ion-segment-button>
        <ion-segment-button (click)="onSubmit()" [disabled]="!formStep2.valid || formDisabled || !formStep2.get('consent')?.value">
          <ion-button fill="outline">Wyślij</ion-button>
        </ion-segment-button>
      </ion-segment>
    </mat-tab>
    <mat-tab>
      <p>Formularz został zapisany.</p>
      <p>Oczekuj na wiadomość e-mail z linkiem aktywacyjnym!</p>
    </mat-tab>
  </mat-tab-group>
</div>
