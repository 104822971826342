import Client from '@app/module/api/infrastructure/client';
import PaginationDto from '@app/module/api/domain/dto/pagination-dto';
import { Injectable } from '@angular/core';

@Injectable()
export default abstract class AbstractPaginatedQuery<T> {
  public static readonly defaultPage = 1;
  public static readonly defaultLimit = 20;

  protected constructor(
    protected readonly client: Client
  ) {}

  protected async paginate(
    path: string,
    page: number = AbstractPaginatedQuery.defaultPage,
    limit: number = AbstractPaginatedQuery.defaultLimit
  ): Promise<PaginationDto<T>> {
    const a: HTMLAnchorElement = document.createElement('a');
    a.href = path;
    const params = new URLSearchParams(a.search);
    params.set('page', page.toString());
    params.set('limit', limit.toString());
    const response = await this.client.method.get(path + '?' + params.toString());
    const data = response.data;

    return new PaginationDto(
      this.mapItems(data.items || []),
      data.currentPageNumber || 0,
      data.pageCount || 0,
      data.itemNumberPerPage || 0,
      data.totalItemCount || 0,
      data.offset || 0,
    );
  }

  protected abstract mapItems(items: any[]): any[];
}
