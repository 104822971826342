import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { CustomElement, customElementParams } from '@app/module/core/application/custom-element/custom-element';
import CustomElementBaseComponent from '@app/module/core/application/custom-element/custom-element-base-component';
import GlobalStyleLoader from '@app/module/core/application/custom-element/global-style-loader';
import { MobxAngularModule } from 'mobx-angular';
import RateModalStore from '@app/module/rate/infrastructure/store/rate-modal-store';
import { IonicModule } from '@ionic/angular';

const { encapsulation, schemas } = customElementParams;

@Component({
  selector: RateModalComponent.ngSelectorName,
  templateUrl: './rate-modal.component.html',
  styleUrls: ['./rate-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  encapsulation,
  schemas,
  imports: [MobxAngularModule, IonicModule],
})
@CustomElement()
export class RateModalComponent extends CustomElementBaseComponent {
  public static override readonly customElementName: string = 'app-rate-modal';
  public static override readonly ngSelectorName: string
    = `${CustomElementBaseComponent.ngPrefix}-${RateModalComponent.customElementName}`;

  @Input() modalTitle?: string;

  constructor(
    ele: ElementRef,
    gsl: GlobalStyleLoader,
    protected readonly rateModalStore: RateModalStore
  ) {
    super(ele, gsl);
  }

  protected override get useGlobalStyle(): boolean {
    return true;
  }

  protected setOpen(isOpen: boolean): void {
    this.rateModalStore.isOpen = isOpen;
  }

  protected onDidDismiss(): void {
    this.setOpen(false);
  }

  protected onDidPresent(): void {
    this.setOpen(true);

  }

  protected onWillPresent(): void {
    this.updateModalContent();
  }

  private getModalContentContainerElement(): Element | null {
    return document.querySelector('#rate-modal-content');
  }

  private getModalContentElements(): HTMLCollection {
    return this.elementRef.nativeElement.children;
  }

  private updateModalContent(): void {
    const container = this.getModalContentContainerElement();

    if (container) {
      container.innerHTML = '';
      for (const el of this.getModalContentElements()) {
        container.append(el.cloneNode(true));
      }
    }
  }
}
