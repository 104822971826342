import { Injectable } from '@angular/core';
import CommandHandler from '@app/module/core/application/command-bus/command-handler';
import OpenRateModalCommand from '@app/module/rate/application/command/open-rate-modal-command';
import RateModalStore from '@app/module/rate/infrastructure/store/rate-modal-store';

@Injectable({
  providedIn: 'root',
})
export default class OpenRateModalHandler implements CommandHandler<OpenRateModalCommand> {
  public readonly commandType: string = OpenRateModalCommand.commandName;

  constructor(
    private readonly rateModalStore: RateModalStore
  ) {}

  public async execute(command: OpenRateModalCommand) {
    this.rateModalStore.isOpen = true;
  }
}
