import { Component, ElementRef } from '@angular/core';
import { CustomElement, customElementParams } from '@app/module/core/application/custom-element/custom-element';
import CustomElementBaseComponent from '@app/module/core/application/custom-element/custom-element-base-component';
import GlobalStyleLoader from '@app/module/core/application/custom-element/global-style-loader';
import CommandBus from '@app/module/core/application/command-bus/command-bus';
import OpenRateModalCommand from '@app/module/rate/application/command/open-rate-modal-command';
import { IonicModule } from '@ionic/angular';

const { encapsulation, schemas } = customElementParams;

@Component({
  selector: RateOpenModalButtonComponent.ngSelectorName,
  templateUrl: './rate-open-modal-button.component.html',
  styleUrls: ['./rate-open-modal-button.component.scss'],
  standalone: true,
  encapsulation,
  schemas,
  imports: [IonicModule],
})
@CustomElement()
export class RateOpenModalButtonComponent extends CustomElementBaseComponent {
  public static override readonly customElementName: string = 'app-rate-open-modal-button';
  public static override readonly ngSelectorName: string
    = `${CustomElementBaseComponent.ngPrefix}-${RateOpenModalButtonComponent.customElementName}`;

  constructor(
    ele: ElementRef,
    gsl: GlobalStyleLoader,
    private readonly commandBus: CommandBus
  ) {
    super(ele, gsl);
  }

  protected override get useGlobalStyle(): boolean {
    return true;
  }

  protected async onClick(): Promise<void> {
    await this.commandBus.execute(new OpenRateModalCommand());
  }
}
