export default class CreateRateAuthorDto {
  constructor(
    public readonly email: string,
    public readonly firstName: string,
    public readonly lastName: string | null = null,
  ) {}

  public static createFromObject(data: any) {
    return new CreateRateAuthorDto(
      data.email,
      data.firstName,
      data.lastName ?? null,
    );
  }
}
