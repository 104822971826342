import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { CustomElement, customElementParams } from '@app/module/core/application/custom-element/custom-element';
import CustomElementBaseComponent from '@app/module/core/application/custom-element/custom-element-base-component';
import GlobalStyleLoader from '@app/module/core/application/custom-element/global-style-loader';
import { IonicModule } from '@ionic/angular';
import { MatTabsModule } from '@angular/material/tabs';
import { RateComponent } from '@app/module/rate/application/elements/rate/rate.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import CreateRateDto from '@app/module/rate/domain/dto/create-rate-dto';
import CommandBus from '@app/module/core/application/command-bus/command-bus';
import CreateRateCommand from '@app/module/rate/application/command/create-rate-command';
import ResponseDto from '@app/module/api/domain/dto/response-dto';
import MessageService from '@app/module/core/application/message/message-service';

const { encapsulation, schemas } = customElementParams;

@Component({
  selector: RateCreateFormComponent.ngSelectorName,
  templateUrl: './rate-create-form.component.html',
  styleUrls: ['./rate-create-form.component.scss'],
  standalone: true,
  encapsulation,
  schemas,
  imports: [IonicModule, MatTabsModule, RateComponent, ReactiveFormsModule],
})
@CustomElement()
export class RateCreateFormComponent extends CustomElementBaseComponent implements OnInit {
  public static override readonly customElementName: string = 'app-rate-create-form';
  public static override readonly ngSelectorName: string
    = `${CustomElementBaseComponent.ngPrefix}-${RateCreateFormComponent.customElementName}`;

  @Input() externalType?: string;
  @Input() externalId?: string;
  @Input() externalResource?: string;

  protected currentStep: number = 0;
  protected formDisabled: boolean = false;
  protected consentText?: string;

  protected readonly formStep1 = new FormGroup({
    rate: new FormControl(),
    title: new FormControl(),
    opinion: new FormControl(),
  });

  protected readonly formStep2 = new FormGroup({
    email: new FormControl(),
    firstName: new FormControl(),
    consent: new FormControl(),
  });

  constructor(
    ele: ElementRef,
    gsl: GlobalStyleLoader,
    private readonly commandBus: CommandBus,
    private readonly messageService: MessageService
  ) {
    super(ele, gsl);
  }

  protected override get useGlobalStyle(): boolean {
    return true;
  }

  ngOnInit(): void {
    this.consentText = this.elementRef.nativeElement.querySelector('[slot="consent-text"]')?.innerHTML;
  }

  protected setCurrentStep(step: number): void {
    this.currentStep = step;
  }

  protected onRateChange(value: number): void {
    this.formStep1.get('rate')?.setValue(value);
  }

  protected async onSubmit(): Promise<void> {
    if (this.formStep1.valid && this.formStep2.valid && !this.formDisabled) {
      const data = this.getData();
      this.formDisabled = true;
      const response: ResponseDto = await this.commandBus.execute(new CreateRateCommand(data));
      this.formDisabled = false;

      if (!response.error) {
        await this.messageService.createSuccess({
          message: 'Formularz został zapisany. Oczekuj na wiadomość e-mail z linkiem aktywacyjnym!'
        });

        this.setCurrentStep(2);
      } else {
        const errors = response.validErrors || [];
        const message = errors.length > 0 ? errors[0].fullMessage : 'Wystąpił błąd!';
        await this.messageService.createError({message});
      }
    }
  }

  protected onConsentChange(event: any): void {
    this.formStep2.get('consent')?.setValue(event.detail.checked);
  }

  protected onConsentContainerClick(event: any): void {
    if (event.target.tagName !== 'ION-CHECKBOX') {
      const container: Element = event.target.classList.contains('app-rate-create-form__checkbox')
        ? event.target
        : event.target.closest('.app-rate-create-form__checkbox')
      ;

      const checkbox = container.querySelector('ion-checkbox');

      if (checkbox) {
        checkbox.checked = !checkbox.checked;
        this.formStep2.get('consent')?.setValue(checkbox.checked);
      }
    }
  }

  private getData(): CreateRateDto {
    return CreateRateDto.createFromObject({
      externalId: this.externalId,
      externalType: this.externalType,
      externalResource: this.externalResource,
      rate: this.formStep1.get('rate')?.value,
      title: this.formStep1.get('title')?.value,
      opinion: this.formStep1.get('opinion')?.value,
      rateAuthor: {
        email: this.formStep2.get('email')?.value,
        firstName: this.formStep2.get('firstName')?.value,
      }
    });
  }
}
