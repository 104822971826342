import { Injectable } from '@angular/core';
import CommandHandler from '@app/module/core/application/command-bus/command-handler';
import UpdateCurrentRateCommand from '@app/module/rate/application/command/update-current-rate-command';
import CurrentRateStore from '@app/module/rate/infrastructure/store/current-rate-store';
import GetAverageRateQuery from '@app/module/rate/infrastructure/query/get-average-rate-query';

@Injectable({
  providedIn: 'root',
})
export default class UpdateCurrentRateHandler implements CommandHandler<UpdateCurrentRateCommand> {
  public readonly commandType: string = UpdateCurrentRateCommand.commandName;

  constructor(
    private readonly currentRateStore: CurrentRateStore,
    private readonly getAverageRateQuery: GetAverageRateQuery
  ) {}

  public async execute(command: UpdateCurrentRateCommand) {
    this.currentRateStore.value = await this.getAverageRateQuery.getAverage(command.externalType, command.externalId);
  }
}
