import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { RateStateComponent } from '@app/module/rate/application/elements/rate-state/rate-state.component';
import {
  RateHandleMessageComponent
} from '@app/module/rate/application/elements/rate-handle-message/rate-handle-message.component';
import { RateListComponent } from '@app/module/rate/application/elements/rate-list/rate-list.component';
import { RateModalComponent } from '@app/module/rate/application/elements/rate-modal/rate-modal.component';
import {
  RateOpenModalButtonComponent
} from '@app/module/rate/application/elements/rate-open-modal-button/rate-open-modal-button.component';
import {
  RateCreateFormComponent
} from '@app/module/rate/application/elements/rate-create-form/rate-create-form.component';
import {
  RateAveragesLoaderComponent
} from '@app/module/rate/application/elements/rate-averages-loader/rate-averages-loader.component';
import { RateValueComponent } from '@app/module/rate/application/elements/rate-value/rate-value.component';

@Component({
  selector: 'app-rates-page',
  templateUrl: './rates-page.component.html',
  styleUrls: ['./rates-page.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    IonicModule,
    RateStateComponent,
    RateHandleMessageComponent,
    RateListComponent,
    RateModalComponent,
    RateOpenModalButtonComponent,
    RateCreateFormComponent,
    RateAveragesLoaderComponent,
    RateValueComponent,
  ]
})
export class RatesPageComponent {
}
