import { Injectable } from '@angular/core';
import { observable } from 'mobx-angular';
import { makeObservable } from 'mobx';
import AverageRateDto from '@app/module/rate/domain/dto/average-rate-dto';

@Injectable({
  providedIn: 'root',
})
export default class CurrentRateStore {
  @observable public value?: AverageRateDto;

  constructor() {
    makeObservable(this);
  }
}
