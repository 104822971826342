import RateAuthorDto from '@app/module/rate/domain/dto/rate-author-dto';
import { DateTime } from 'luxon';
import RateReplyListItemDto from '@app/module/rate/domain/dto/rate-reply-list-item-dto';

export default class RateListItemDto {
  constructor(
    public readonly rate: number,
    public readonly title: string,
    public readonly opinion: string,
    public readonly author: RateAuthorDto,
    public readonly replies: RateReplyListItemDto[],
    public readonly createdAt: DateTime | null,
  ) {}

  public static createFromObject(data: any): RateListItemDto {
    return new RateListItemDto(
      data.rate || 0,
      data.title || '',
      data.opinion || '',
      RateAuthorDto.createFromObject(data.author || {}),
      Array.isArray(data.replies)
        ? data.replies.map((reply: any) => RateReplyListItemDto.createFromObject(reply)) : [],
      data.createdAt ? DateTime.fromISO(data.createdAt) : null,
    );
  }
}
