import { Injectable } from '@angular/core';
import CommandHandler from '@app/module/core/application/command-bus/command-handler';
import LoadRatesAveragesCommand from '@app/module/rate/application/command/load-rates-averages-command';
import GetAverageRateManyQuery from '@app/module/rate/infrastructure/query/get-average-rate-many-query';
import AverageRateDto from '@app/module/rate/domain/dto/average-rate-dto';
import AverageRateMap from '@app/module/rate/domain/dto/average-rate-map';
import AverageRateMapStore from '@app/module/rate/infrastructure/store/average-rate-map-store';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export default class LoadRatesAveragesHandler implements CommandHandler<LoadRatesAveragesCommand> {
  public readonly commandType: string = LoadRatesAveragesCommand.commandName;

  constructor(
    private readonly getAverageRateManyQuery: GetAverageRateManyQuery,
    private readonly averageRateMapStore: AverageRateMapStore
  ) {}

  public async execute(command: LoadRatesAveragesCommand) {
    const averages: AverageRateDto[] = await this.getAverageRateManyQuery.getAverages(command.dto);
    const averageMap: AverageRateMap = AverageRateMap.createFromArray(averages);

    for (const [key, value] of averageMap) {
      this.averageRateMapStore.averageMap.set(key, value);
    }

    this.averageRateMapStore.updateDate = DateTime.now();
  }
}
