import AverageRateDto from '@app/module/rate/domain/dto/average-rate-dto';

export default class AverageRateMap extends Map<string, AverageRateDto> {
  public static createFromArray(array: AverageRateDto[]): AverageRateMap {
    const map = new AverageRateMap();

    for (const item of array) {
      map.set(`${item.type},${item.id}`, item);
    }

    return map;
  }
}
