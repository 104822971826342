import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { CustomElement, customElementParams } from '@app/module/core/application/custom-element/custom-element';
import CustomElementBaseComponent from '@app/module/core/application/custom-element/custom-element-base-component';
import GlobalStyleLoader from '@app/module/core/application/custom-element/global-style-loader';
import MessageService from '@app/module/core/application/message/message-service';
import CommandBus from '@app/module/core/application/command-bus/command-bus';
import UpdateCurrentRateCommand from '@app/module/rate/application/command/update-current-rate-command';
import LoadCurrentRateListCommand from '@app/module/rate/application/command/load-current-rate-list-command';
import RateListLimitDefault from '@app/module/rate/domain/default/rate-list-limit-default';

const { encapsulation, schemas } = customElementParams;

@Component({
  selector: RateHandleMessageComponent.ngSelectorName,
  templateUrl: './rate-handle-message.component.html',
  styleUrls: ['./rate-handle-message.component.scss'],
  standalone: true,
  encapsulation,
  schemas,
})
@CustomElement()
export class RateHandleMessageComponent extends CustomElementBaseComponent implements OnInit {
  public static override readonly customElementName: string = 'app-rate-handle-message';
  public static override readonly ngSelectorName: string
    = `${CustomElementBaseComponent.ngPrefix}-${RateHandleMessageComponent.customElementName}`;

  @Input() externalType?: string;
  @Input() externalId?: string;
  @Input() limit: string = RateListLimitDefault.limit.toString();

  constructor(
    ele: ElementRef,
    gsl: GlobalStyleLoader,
    private readonly messageService: MessageService,
    private readonly commandBus: CommandBus
  ) {
    super(ele, gsl);
  }

  ngOnInit() {
    this.handle();
  }

  private handle(): void {
    this.handleSuccess();
    this.handleError();
  }

  private handleSuccess(): void {
    const params = this.getCurrentParams();

    if (params.has('success')) {
      this.messageService.createSuccess({message: params.get('success') as string});

      if (this.externalType && this.externalId) {
        this.commandBus.execute(new UpdateCurrentRateCommand(this.externalType, this.externalId));
        this.commandBus.execute(new LoadCurrentRateListCommand(this.externalType, this.externalId, 1, parseInt(this.limit)));
      }
    }
  }

  private handleError(): void {
    const params = this.getCurrentParams();

    if (params.has('error')) {
      this.messageService.createError({message: params.get('error') as string});
    }
  }

  private getCurrentParams(): URLSearchParams {
    return new URLSearchParams(document.location.search);
  }
}
