import { Injectable } from '@angular/core';
import Client from '@app/module/api/infrastructure/client';
import AverageRateDto from '@app/module/rate/domain/dto/average-rate-dto';
import CalculateAverageRateRequestDto from '@app/module/rate/domain/dto/calculate-average-rate-request-dto';

@Injectable({
  providedIn: 'root',
})
export default class GetAverageRateManyQuery {
  constructor(
    private readonly client: Client
  ) {}

  public async getAverages(dto: CalculateAverageRateRequestDto): Promise<AverageRateDto[]> {
    const response = await this.client.method.post('rate/calculate-average-rates', dto);
    const items = response.data;
    const averages: AverageRateDto[] = [];

    for (const item of items) {
      averages.push(new AverageRateDto(item.average, item.count, item.type, item.id));
    }

    return averages;
  }
}
