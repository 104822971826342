import ValidationErrorDto from '@app/module/api/domain/dto/validation-error-dto';

export default class ResponseDto {
  constructor(
    public readonly requestValid?: boolean,
    public readonly validErrors?: ValidationErrorDto[],
    public readonly message?: string,
    public readonly error?: boolean,
    public readonly errorMessage?: string,
    public readonly responseData?: object | null,
  ) {}
}
