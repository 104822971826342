/// <reference types="@angular/localize" />

import { enableProdMode, EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { configure } from 'mobx';

configure({
  enforceActions: 'never',
});

import { routes } from '@app/app.routes';
import { AppComponent } from '@app/app.component';
import { environment } from './environments/environment';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MatPaginatorIntl } from '@angular/material/paginator';
import PaginatorIntl from '@app/module/elements/application/paginator-intl/paginator-intl';

if (environment.production) {
  enableProdMode();
}

const providers: Array<Provider | EnvironmentProviders> = [
  { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  importProvidersFrom(IonicModule.forRoot({})),
  provideAnimations(),
  { provide: MatPaginatorIntl, useClass: PaginatorIntl }
];

if (!environment.production) {
  providers.push(provideRouter(routes));
}

bootstrapApplication(AppComponent, {
  providers,
});
