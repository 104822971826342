import { Injectable } from '@angular/core';
import CommandHandler from '@app/module/core/application/command-bus/command-handler';
import LoadCurrentRateListCommand from '@app/module/rate/application/command/load-current-rate-list-command';
import CurrentRateListStore from '@app/module/rate/infrastructure/store/current-rate-list-store';
import GetRateListQuery from '@app/module/rate/infrastructure/query/get-rate-list-query';

@Injectable({
  providedIn: 'root',
})
export default class LoadCurrentRateListHandler implements CommandHandler<LoadCurrentRateListCommand> {
  public readonly commandType: string = LoadCurrentRateListCommand.commandName;

  constructor(
    private readonly currentRateListStore: CurrentRateListStore,
    private readonly getRateListQuery: GetRateListQuery
  ) {}

  public async execute(command: LoadCurrentRateListCommand) {
    this.currentRateListStore.pagination = await this.getRateListQuery.getList(
      command.externalType,
      command.externalId,
      command.page,
      command.limit
    );
  }
}
