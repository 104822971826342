<div *mobxAutorun="{ detach: false }">
  <ion-modal [isOpen]="rateModalStore.isOpen"
             (didDismiss)="onDidDismiss()"
             (didPresent)="onDidPresent()"
             (willPresent)="onWillPresent()">
    <ng-template>
      <ion-header>
        <ion-toolbar>
          <ion-title>{{ modalTitle }}</ion-title>
          <ion-buttons slot="end">
            <ion-button (click)="setOpen(false)">✕</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding" id="rate-modal-content"></ion-content>
    </ng-template>
  </ion-modal>
</div>
