import { inject } from '@angular/core';
import CommandHandlerRegistry from '@app/module/core/application/command-bus/command-handler-registry';
import UpdateCurrentRateHandler from '@app/module/rate/application/command-handler/update-current-rate-handler';
import LoadCurrentRateListHandler from '@app/module/rate/application/command-handler/load-current-rate-list-handler';
import OpenRateModalHandler from '@app/module/rate/application/command-handler/open-rate-modal-handler';
import CreateRateHandler from '@app/module/rate/application/command-handler/create-rate-handler';
import LoadRatesAveragesHandler from '@app/module/rate/application/command-handler/load-rates-averages-handler';

export default function rateLoader() {
  inject(CommandHandlerRegistry).registerAny([
    inject(UpdateCurrentRateHandler),
    inject(LoadCurrentRateListHandler),
    inject(OpenRateModalHandler),
    inject(CreateRateHandler),
    inject(LoadRatesAveragesHandler),
  ]);
}
