import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { CustomElement, customElementParams } from '@app/module/core/application/custom-element/custom-element';
import CustomElementBaseComponent from '@app/module/core/application/custom-element/custom-element-base-component';
import GlobalStyleLoader from '@app/module/core/application/custom-element/global-style-loader';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import CurrentRateListStore from '@app/module/rate/infrastructure/store/current-rate-list-store';
import CommandBus from '@app/module/core/application/command-bus/command-bus';
import LoadCurrentRateListCommand from '@app/module/rate/application/command/load-current-rate-list-command';
import { MobxAngularModule } from 'mobx-angular';
import { NgForOf, NgIf } from '@angular/common';
import { RateComponent } from '@app/module/rate/application/elements/rate/rate.component';
import { LuxonModule } from 'luxon-angular';
import Nl2brPipe from '@app/module/core/application/pipe/nl2br.pipe';
import RateListLimitDefault from '@app/module/rate/domain/default/rate-list-limit-default';

const { encapsulation, schemas } = customElementParams;

@Component({
  selector: RateListComponent.ngSelectorName,
  templateUrl: './rate-list.component.html',
  styleUrls: ['./rate-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  encapsulation,
  schemas,
  imports: [RateComponent, MatPaginatorModule, MobxAngularModule, NgIf, NgForOf, LuxonModule, Nl2brPipe],
})
@CustomElement()
export class RateListComponent extends CustomElementBaseComponent implements OnInit {
  public static override readonly customElementName: string = 'app-rate-list';
  public static override readonly ngSelectorName: string
    = `${CustomElementBaseComponent.ngPrefix}-${RateListComponent.customElementName}`;

  @Input() externalType?: string;
  @Input() externalId?: string;
  @Input() limit: string = RateListLimitDefault.limit.toString();
  @Input() showPagination: string = 'yes';
  @Input() showDate: string = 'yes';
  @Input() showHour: string = 'yes';

  protected paginationDisabled: boolean = false;

  constructor(
    ele: ElementRef,
    gsl: GlobalStyleLoader,
    protected readonly currentRateListStore: CurrentRateListStore,
    private readonly commandBus: CommandBus
  ) {
    super(ele, gsl);
  }

  protected override get useGlobalStyle(): boolean {
    return true;
  }

  async ngOnInit() {
    await this.loadList();
  }

  protected async onPageEvent(e: PageEvent) {
    await this.loadList(e.pageIndex + 1);
  }

  private async loadList(page: number = 1): Promise<void> {
    this.paginationDisabled = true;

    try {
      await this.commandBus.execute(new LoadCurrentRateListCommand(
        this.externalType as string,
        this.externalId as string,
        page,
        parseInt(this.limit)
      ));
    } catch (e) {
      this.paginationDisabled = false;
      throw e;
    }

    this.paginationDisabled = false;
  }
}
