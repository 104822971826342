import { DateTime } from 'luxon';

export default class RateReplyListItemDto {
  constructor(
    public readonly reply: string,
    public readonly authorName: string,
    public readonly createdAt: DateTime | null,
  ) {}

  public static createFromObject(data: any): RateReplyListItemDto {
    return new RateReplyListItemDto(
      data.reply || '',
      data.authorName || '',
      data.createdAt ? DateTime.fromISO(data.createdAt) : null,
    );
  }
}
