import { Injectable } from '@angular/core';
import axios, { CreateAxiosDefaults } from 'axios';
import Config from '@app/module/core/application/config/config';
import ValidationErrorDto from '@app/module/api/domain/dto/validation-error-dto';
import ResponseDto from '@app/module/api/domain/dto/response-dto';

@Injectable({
  providedIn: 'root',
})
export default class Client {
  private client: any;

  constructor(
    private readonly config: Config
  ) {
    this.init();
  }

  public get method() {
    return this.client;
  }

  public getResponse(responseData: any): ResponseDto {
    return new ResponseDto(
      responseData.requestValid,
      this.getValidationErrors(responseData),
      responseData.message,
      responseData.error ?? true,
      responseData.errorMessage,
      responseData.responseData,
    );
  }

  public getValidationErrors(responseData: any): ValidationErrorDto[] {
    const errors: Object[] = typeof responseData === 'object' && Array.isArray(responseData.validErrors)
      ? responseData.validErrors
      : [];

    const dtoErrors: ValidationErrorDto[] = [];
    for (const error of errors) {
      dtoErrors.push(ValidationErrorDto.createFromObject(error));
    }

    return dtoErrors;
  }

  public getFirstValidationError(responseData: any, defaultMessage: string = ''): string {
    const errors = this.getValidationErrors(responseData);

    return errors.length > 0 ? errors[0].fullMessage : defaultMessage;
  }

  private init() {
    const apiPath = '/api/';
    this.client = axios.create({
      baseURL: (this.config.getApiBaseUrl() + apiPath).replace(`/${apiPath}`, apiPath)
    } as CreateAxiosDefaults);
  }
}
