import { Routes } from '@angular/router';
import { RatesPageComponent } from '@app/module/pages/application/pages/rates-page/rates-page.component';

export const pagesRoutes: Routes = [
  {
    path: 'page/rates',
    component: RatesPageComponent
  },
  {
    path: '',
    redirectTo: 'page/rates',
    pathMatch: 'full',
  },
];
