import { Injectable } from '@angular/core';
import { observable } from 'mobx-angular';
import { makeObservable } from 'mobx';
import { DateTime } from 'luxon';
import AverageRateMap from '@app/module/rate/domain/dto/average-rate-map';

@Injectable({
  providedIn: 'root',
})
export default class AverageRateMapStore {
  @observable public updateDate: DateTime = DateTime.now();
  public averageMap: AverageRateMap = new AverageRateMap();

  constructor() {
    makeObservable(this);
  }
}
