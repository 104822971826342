export default class RateAuthorDto {
  constructor(
    public readonly firstName: string,
    public readonly lastName: string,
  ) {}

  public static createFromObject(data: any): RateAuthorDto {
    return new RateAuthorDto(
      data.firstName || '',
      data.lastName || '',
    );
  }
}
