import { Injectable } from '@angular/core';
import CommandHandler from '@app/module/core/application/command-bus/command-handler';
import CreateRateCommand from '@app/module/rate/application/command/create-rate-command';
import Client from '@app/module/api/infrastructure/client';
import ResponseDto from '@app/module/api/domain/dto/response-dto';

@Injectable({
  providedIn: 'root',
})
export default class CreateRateHandler implements CommandHandler<CreateRateCommand> {
  public readonly commandType: string = CreateRateCommand.commandName;

  constructor(
    private readonly client: Client,
  ) {}

  public async execute(command: CreateRateCommand): Promise<ResponseDto> {
    let response: ResponseDto;

    try {
      const tokenResult = await this.client.method.get('rate/csrf-token');
      command.dto.csrfToken = tokenResult.data;
      const result = await this.client.method.post('rate/', command.dto);
      response = this.client.getResponse(result.data);
    } catch (error: any) {
      response = this.client.getResponse(error.response ? error.response.data : {});
    }

    return response;
  }
}
