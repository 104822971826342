import Command from '@app/module/core/application/command-bus/command';
import CalculateAverageRateRequestDto from '@app/module/rate/domain/dto/calculate-average-rate-request-dto';

export default class LoadRatesAveragesCommand extends Command {
  constructor(
    public readonly dto: CalculateAverageRateRequestDto,
  ) {
    super();
  }
}
