import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rate_count',
  standalone: true,
})
export default class RateCountPipe implements PipeTransform {
  transform(value: number | null): string {
    let text: string;
    const lastDigit: string = (value ?? 0).toString().slice(-1);

    if (lastDigit === '1') {
      text = 'opinia';
    } else if (['2', '3', '4'].includes(lastDigit) && ![12, 13, 14].includes(value ?? 0)) {
      text = 'opinie';
    } else {
      text = 'opinii';
    }

    return `${value ?? 0} ${text}`;
  }
}
