<div class="container app-rates-page">
  <h2>Jan Kowalski</h2>
  <div>
    <a href="/page/rates#rate-list">
      <ng-app-rate-state externalType="lawyer" externalId="1" aria-label="Średnia ocena"></ng-app-rate-state>
    </a>
  </div>
  <div>
    <ng-app-rate-open-modal-button role="button">
      Dodaj opinię
    </ng-app-rate-open-modal-button>
  </div>
  <ng-app-rate-handle-message externalType="lawyer" externalId="1"></ng-app-rate-handle-message>
  <hr />
  <div class="row">
    <div class="col-md-7">
      <ng-app-rate-list
        id="rate-list"
        externalType="lawyer"
        externalId="1"
        aria-label="Lista opinii">
      </ng-app-rate-list>
    </div>
    <div class="col-md-5">
      <h4>Formularz dodawania opinii</h4>
      <ng-app-rate-create-form
        role="form"
        externalType="lawyer"
        externalId="1"
        externalResource="http://localhost:4200/page/rates">
        <span slot="consent-text">
          Wyrażam zgodę na przetwarzanie moich danych osobowych.
        </span>
      </ng-app-rate-create-form>
    </div>
  </div>
  <ng-app-rate-modal modalTitle="Dodaj opinię">
    <app-rate-create-form
      role="form"
      external-type="lawyer"
      external-id="1"
      external-resource="http://localhost:4200/page/rates">
      <span slot="consent-text">
        Wyrażam zgodę na przetwarzanie moich danych osobowych.
      </span>
    </app-rate-create-form>
  </ng-app-rate-modal>
</div>
