<div *mobxAutorun="{ detach: false }">
  <section class="app-rate-list">
    <ng-template ngFor let-item [ngForOf]="currentRateListStore.pagination?.items" let-i="index">
      <div class="app-rate-list-item">
        <div class="app-rate-list-item__top">
          <ng-app-rate class="app-rate-list-item__rate" [value]="item.rate" [readonly]="true" [size]="16"></ng-app-rate>
          <ng-template [ngIf]="showDate === 'yes'">
            <span class="app-rate-list-item__date">
              {{ item.createdAt | dateTimeToFormat:showHour === 'yes' ? 'yyyy-LL-dd HH:mm:ss' : 'yyyy-LL-dd' }}
            </span>
          </ng-template>
        </div>
        <div class="app-rate-list-item__title">
          <b>{{ item.title }}</b>
        </div>
        <div class="app-rate-list-item__author">
          <span>{{ item.author.firstName }} {{ item.author.lastName }} </span>
          <span>napisał(a):</span>
        </div>
        <p class="app-rate-list-item__opinion" [innerHTML]="item.opinion | nl2br"></p>
      </div>
      <ng-template ngFor let-reply [ngForOf]="item.replies" let-i="index">
        <div class="app-rate-list-reply">
          <div class="app-rate-list-item">
            <div class="app-rate-list-item__top">
              <ng-template [ngIf]="showDate === 'yes'">
                <span class="app-rate-list-item__date">
                  {{ reply.createdAt | dateTimeToFormat:showHour === 'yes' ? 'yyyy-LL-dd HH:mm:ss' : 'yyyy-LL-dd' }}
                </span>
              </ng-template>
            </div>
            <div class="app-rate-list-item__author">
              <span>{{ reply.authorName }} </span>
              <span>napisał(a):</span>
            </div>
            <p class="app-rate-list-item__opinion" [innerHTML]="reply.reply | nl2br"></p>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </section>
  <ng-template [ngIf]="currentRateListStore.pagination && showPagination === 'yes'">
    <mat-paginator (page)="onPageEvent($event)"
                   [disabled]="paginationDisabled"
                   [length]="currentRateListStore.pagination.totalItemCount"
                   [pageSize]="currentRateListStore.pagination.itemNumberPerPage"
                   [showFirstLastButtons]="true"
                   [pageIndex]="currentRateListStore.pagination.currentPageNumber - 1"
                   [hidePageSize]="true"
                   aria-label="Paginacja">
    </mat-paginator>
  </ng-template>
</div>
