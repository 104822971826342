import { Injectable } from '@angular/core';
import Client from '@app/module/api/infrastructure/client';
import AverageRateDto from '@app/module/rate/domain/dto/average-rate-dto';

@Injectable({
  providedIn: 'root',
})
export default class GetAverageRateQuery {
  constructor(
    private readonly client: Client
  ) {}

  public async getAverage(type: string, id: string): Promise<AverageRateDto> {
    const response = await this.client.method.get(`rate/average-rate/${type}/${id}`);
    const data = response.data;

    return new AverageRateDto(data.average, data.count, data.type, data.id);
  }
}
