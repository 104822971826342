import { MatPaginatorIntl } from '@angular/material/paginator';

export default class PaginatorIntl extends MatPaginatorIntl
{
  constructor() {
    super();
    const withLabel = 'z';
    this.firstPageLabel = 'Pierwsza strona';
    this.lastPageLabel = 'Ostatnia strona';
    this.nextPageLabel = 'Następna';
    this.previousPageLabel = 'Poprzednia';
    this.itemsPerPageLabel = 'Pozycji na stronę';

    this.getRangeLabel = (page: number, pageSize: number, length: number): string => {
      if (length === 0 || pageSize === 0) {
        return `0 ${withLabel} ${length}`;
      }

      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} ${withLabel} ${length}`;
    }
  }
}
