import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { CustomElement, customElementParams } from '@app/module/core/application/custom-element/custom-element';
import CustomElementBaseComponent from '@app/module/core/application/custom-element/custom-element-base-component';
import GlobalStyleLoader from '@app/module/core/application/custom-element/global-style-loader';
import { RateComponent } from '@app/module/rate/application/elements/rate/rate.component';
import CurrentRateStore from '@app/module/rate/infrastructure/store/current-rate-store';
import { MobxAngularModule } from 'mobx-angular';
import CommandBus from '@app/module/core/application/command-bus/command-bus';
import UpdateCurrentRateCommand from '@app/module/rate/application/command/update-current-rate-command';
import RateCountPipe from '@app/module/rate/application/pipe/rate-count.pipe';

const { encapsulation, schemas } = customElementParams;

@Component({
  selector: RateStateComponent.ngSelectorName,
  templateUrl: './rate-state.component.html',
  styleUrls: ['./rate-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  encapsulation,
  schemas,
  imports: [RateComponent, MobxAngularModule, RateCountPipe],
})
@CustomElement()
export class RateStateComponent extends CustomElementBaseComponent implements OnInit {
  public static override readonly customElementName: string = 'app-rate-state';
  public static override readonly ngSelectorName: string
    = `${CustomElementBaseComponent.ngPrefix}-${RateStateComponent.customElementName}`;

  @Input() size: number = 32;
  @Input() externalType?: string;
  @Input() externalId?: string;

  constructor(
    ele: ElementRef,
    gsl: GlobalStyleLoader,
    protected readonly currentRateStore: CurrentRateStore,
    private readonly commandBus: CommandBus
  ) {
    super(ele, gsl);
  }

  protected override get useGlobalStyle(): boolean {
    return true;
  }

  async ngOnInit(): Promise<void> {
    if (this.externalType && this.externalId) {
      await this.commandBus.execute(new UpdateCurrentRateCommand(this.externalType, this.externalId));
    }
  }
}
