import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CustomElement, customElementParams } from '@app/module/core/application/custom-element/custom-element';
import CustomElementBaseComponent from '@app/module/core/application/custom-element/custom-element-base-component';
import GlobalStyleLoader from '@app/module/core/application/custom-element/global-style-loader';
import * as $ from 'jquery';
import '@app/library/star-rating-svg/jquery.star-rating-svg.js';

const { encapsulation, schemas } = customElementParams;

@Component({
  selector: RateComponent.ngSelectorName,
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss'],
  standalone: true,
  encapsulation,
  schemas,
})
@CustomElement()
export class RateComponent extends CustomElementBaseComponent implements OnInit, OnChanges {
  public static override readonly customElementName: string = 'app-rate';
  public static override readonly ngSelectorName: string
    = `${CustomElementBaseComponent.ngPrefix}-${RateComponent.customElementName}`;
  private isInit: boolean = false;

  @Input() readonly: boolean = false;
  @Input() value: number = 0;
  @Input() min: number = 0;
  @Input() max: number = 5;
  @Input() size: number = 32;
  @Input() disableAfterRate: boolean = true;

  @Output() changeEvent = new EventEmitter<number>();

  constructor(ele: ElementRef, gsl: GlobalStyleLoader) {
    super(ele, gsl);
  }

  protected override get useGlobalStyle(): boolean {
    return true;
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.setValue(changes['value'].currentValue);
    }
  }

  private getElement(): Element {
    return this.getShadowRoot().querySelector('.app-rate-el') as Element;
  }

  private init(): void {
   ($(this.getElement()) as any).starRating({
      readOnly: this.readonly,
      initialRating: this.value,
      minRating: this.min,
      totalStars: this.max,
      starSize: this.size,
      useFullStars: true,
      disableAfterRate: this.disableAfterRate,
      callback: (value: number) => {
        this.value = value;
        this.changeEvent.emit(value);
      }
    });

   this.isInit = true;
  }

  private setValue(value: number): void {
    if (this.isInit) {
      ($(this.getElement()) as any).starRating('setRating', value);
    }
  }
}
